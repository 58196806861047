// src/components/LoadScripts.js

import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Ensure this line is included
const LoadScripts = ({ scriptsToLoad, cssToLoad }) => {
  useEffect(() => {
    const scripts = [];
    const links = [];

    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
        scripts.push(script);
      });
    };

    const loadCSS = (href) => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.onload = () => resolve();
        link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`));
        document.head.appendChild(link);
        links.push(link);
      });
    };

    const loadAssets = async () => {
      try {
        for (const css of cssToLoad) {
          await loadCSS(css);
        }
        
        for (const script of scriptsToLoad) {
          await loadScript(script);
        }
      } catch (error) {
        console.error('Error loading assets:', error);
      }
    };

    loadAssets();

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
      links.forEach((link) => {
        document.head.removeChild(link);
      });
    };
  }, [scriptsToLoad, cssToLoad]);

  return null;
};
LoadScripts.propTypes = {
    scriptsToLoad: PropTypes.arrayOf(PropTypes.string).isRequired,
    cssToLoad: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

export default LoadScripts;
