// src/pages/Home.js
import React from "react";
import { Helmet } from 'react-helmet-async';
class About extends React.Component {

    render() {
        return (
            <>
            <Helmet>
      
      <title>About - EDA COLLEGE</title>
      
      <meta name="description" content="Welcome to EDA College's" />
      <link rel="icon" type="image/png" href="/images/edalogo.png" sizes="16x16" />

      </Helmet>
                <section className="skrollable skrollable-between u-align-center u-clearfix u-section-1" id="carousel_fabd" src="">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <h2 className="u-align-center u-text u-text-default u-text-palette-3-light-2 u-text-1"
                            data-animation-name="customAnimationIn" data-animation-duration="1500">
                            Welcome to EDA COLLEGE
                        </h2>
                        <p className="u-align-center u-large-text u-text u-text-variant u-text-2" data-animation-name="customAnimationIn"
                            data-animation-duration="1500" data-animation-delay="250">
                            “Streamline your career with EDA”<br />
                            <br />By studying at EDA, you base yourself in the most vibrant
                            surroundings to stimulate you. We are located in Ilford, East London,
                            an area embracing the 21st Century Lifestyle, in a thriving mixed
                            culture environment. Our college is less than a mile from the Ilford
                            City Centre.&nbsp;<br />
                            <br />We are proud to provide high quality learning opportunities that
                            match the expectations for our students of all ages and backgrounds.
                            At the moment, we offer programmes in English Language, Basic Skills,
                            Information Technology, Engineering, Health and Social Care,
                            Environmental Sustainability, Business and Professional Studies. With
                            a wide range of ever growing academic options, both full-time and
                            part-time, we have something for everyone.<br />
                            <br />We aim to achieve operational excellence and work towards
                            provision of the best education services. We are very sensitive to all
                            the needs of our valued stakeholders including our students, faculty,
                            staff, alumni, and the large external community that we serve. We are
                            confident that with our hard work and strive to succeed, we will make
                            EDA, a national leader in education all over the UK.
                        </p>
                    </div>
                </section>
                <section className="u-clearfix u-image u-shading u-section-2" id="carousel_0558" data-image-width="1980"
                    data-image-height="1113">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-gutter-20 u-layout-wrap u-layout-wrap-1">
                            <div className="u-gutter-0 u-layout">
                                <div className="u-layout-row">
                                    <div className="u-align-left u-container-style u-gradient u-layout-cell u-opacity u-opacity-40 u-size-15 u-size-30-md u-layout-cell-1"
                                        data-animation-name="customAnimationIn" data-animation-duration="1000">
                                        <div className="u-container-layout u-container-layout-1">
                                            <h4 className="u-text u-text-1">Vi​sion</h4>
                                            <p className="u-text u-text-2">
                                                Continuous growth of our education services and to become
                                                the secret behind every individual’s successful
                                                career.&nbsp;
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-gradient u-layout-cell u-size-15 u-size-30-md u-layout-cell-2"
                                        data-animation-name="customAnimationIn" data-animation-duration="1000">
                                        <div className="u-container-layout u-valign-top u-container-layout-2">
                                            <h4 className="u-text u-text-default u-text-3">
                                                Mission Statement
                                            </h4>
                                            <p className="u-text u-text-default u-text-4">
                                                To maximise intellectual and personal growth of individuals
                                                within our local community, by providing high-quality and
                                                innovative educational programmes.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-gradient u-layout-cell u-size-15 u-size-30-md u-layout-cell-3"
                                        data-animation-name="customAnimationIn" data-animation-duration="1000">
                                        <div className="u-container-layout u-container-layout-3">
                                            <h4 className="u-text u-text-default u-text-5">
                                                Keys to Success
                                            </h4>
                                            <p className="u-text u-text-default u-text-6">
                                                • The burning desire of the founders to achieve the mission
                                                and vision of the business.<br />• Keeping our students at
                                                the centre of our organization, our thoughts and our
                                                plans.&nbsp;<br />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-gradient u-layout-cell u-size-15 u-size-30-md u-layout-cell-4"
                                        data-animation-name="customAnimationIn" data-animation-duration="1000">
                                        <div className="u-container-layout u-container-layout-4">
                                            <h4 className="u-text u-text-default u-text-7">Objectives</h4>
                                            <p className="u-text u-text-default u-text-8">
                                                • To have our education services running successfully
                                                throughout UK.&nbsp;<br />• To establish the brand of EDA as
                                                the secret behind every individual’s career
                                                success.&nbsp;&nbsp;<br />• Excellence in our Operational
                                                performance.&nbsp;
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default About;
