// src/pages/Home.js
import React from "react";
import { Helmet } from 'react-helmet-async';

class Contact extends React.Component {
    
    render() {
        return (
            <>
            <Helmet>
      
                <title>Contact - EDA COLLEGE</title>
                
                <meta name="description" content="Welcome to EDA College's" />
                <link rel="icon" type="image/png" href="/images/edalogo.png" sizes="16x16" />

                </Helmet>
                {/* <section className="u-clearfix u-section-1" id="carousel_fb1d">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-border-9 u-border-palette-3-base u-shape u-shape-rectangle u-shape-1"></div>
                        <div className="u-grey-light-2 u-map u-map-1" data-animation-name="customAnimationIn" data-animation-duration="1000">
                            <div className="embed-responsive">
                                
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.591636214154!2d0.07193248656301426!3d51.55738672129291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a68aa21e4517%3A0xb873cdf1dbd50149!2sOLYMPIC%20HOUSE!5e0!3m2!1sen!2suk!4v1727434720410!5m2!1sen!2suk" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-custom-color-1 u-group u-group-1" data-animation-name="customAnimationIn" data-animation-duration="1000">
                            <div className="u-container-layout u-valign-middle u-container-layout-1">
                                <h4 className="u-text u-text-1">EDA COLLEGE LTD</h4>
                                <p className="u-text u-text-font u-text-2">
                                    <b>Address:</b><br />
                                    206 Olympic House, 28-42 Clements Road,<br />
                                    England IG1 1BA<br /><br />
                                    <b>Tel:</b><br /> 
                                    02039 300478<br /><br />
                                    <b>Email:</b><br /> 
                                    info@edacollege.co.uk<br />
                                </p>
                                <p className="u-text u-text-3">Follow us:</p>
                                <div className="u-social-icons u-spacing-13 u-social-icons-1">
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/wiclondon" title="WIC Facebook">
                                        <span className="u-file-icon u-icon u-social-facebook u-social-icon u-icon-1">
                                            <img src="/images/1384879.png" alt="Facebook" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://mobile.twitter.com/walthamcollege" title="WIC Twitter">
                                        <span className="u-file-icon u-icon u-social-icon u-social-twitter u-icon-2">
                                            <img src="/images/4926492.png" alt="Twitter" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/wic.london/" title="WIC Instagram">
                                        <span className="u-file-icon u-icon u-social-icon u-social-instagram u-icon-3">
                                            <img src="/images/3955027.png" alt="Instagram" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" data-type="LinkedIn" title="WIC LinkedIn" href="https://www.linkedin.com/company/waltham-international-college/mycompany/">
                                        <span className="u-icon u-social-icon u-social-linkedin u-icon-4">
                                            <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-6990"></use>
                                            </svg>
                                            <svg className="u-svg-content" viewBox="0 0 112 112" id="svg-6990">
                                                <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                                                <path fill="#FFFFFF" d="M41.3,83.7H27.9V43.4h13.4V83.7z M34.6,37.9c-4.6,0-7.5-3.1-7.5-7c0-4,3-7,7.6-7s7.4,3,7.5,7 C42.2,34.8,39.2,37.9,34.6,37.9z M89.6,83.7H76.2V62.2c0-5.4-1.9-9.1-6.8-9.1c-3.7,0-5.9,2.5-6.9,4.9c-0.4,0.9-0.4,2.1-0.4,3.3v22.5 H48.7c0,0,0.2-36.5,0-40.3h13.4v5.7c1.8-2.7,5-6.7,12.1-6.7c8.8,0,15.4,5.8,15.4,18.1V83.7z"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="u-clearfix u-section-2" id="carousel_c4ff">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-border-9 u-border-palette-3-base u-shape u-shape-rectangle u-shape-1"></div>
                        <div className="u-grey-light-2 u-map u-map-1" data-animation-name="customAnimationIn" data-animation-duration="1000">
                            <div className="embed-responsive">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.1058287807896!2d-1.913727822990902!3d52.47721953939558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bdb1aefddba7%3A0xfd2a0fdcb2852e7a!2sEda%20College%20Ltd!5e0!3m2!1sen!2suk!4v1727434869486!5m2!1sen!2suk" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-custom-color-1 u-group u-group-1" data-animation-name="customAnimationIn" data-animation-duration="1000">
                            <div className="u-container-layout u-valign-middle u-container-layout-1">
                                <h4 className="u-text u-text-1">EDA COLLEGE LTD</h4>
                                <p className="u-text u-text-font u-text-2">
                                    <b>Address:</b><br />
                                    Quayside 16th Floor 260 Broad Street, Birmingham, B1 2HF<br /><br />
                                    <b>Tel:</b><br /> 
                                    +44 330 088 0332<br /><br />
                                    <b>Email:</b><br /> 
                                    info@edacollege.co.uk<br />
                                </p>
                                <p className="u-text u-text-3">Follow us:</p>
                                <div className="u-social-icons u-spacing-13 u-social-icons-1">
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/wiclondon" title="WIC Facebook">
                                        <span className="u-file-icon u-icon u-social-facebook u-social-icon u-icon-1">
                                            <img src="/images/1384879.png" alt="Facebook" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://mobile.twitter.com/walthamcollege" title="WIC Twitter">
                                        <span className="u-file-icon u-icon u-social-icon u-social-twitter u-icon-2">
                                            <img src="/images/4926492.png" alt="Twitter" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/wic.london/" title="WIC Instagram">
                                        <span className="u-file-icon u-icon u-social-icon u-social-instagram u-icon-3">
                                            <img src="/images/3955027.png" alt="Instagram" />
                                        </span>
                                    </a>
                                    <a className="u-social-url" target="_blank" rel="noopener noreferrer" data-type="LinkedIn" title="WIC LinkedIn" href="https://www.linkedin.com/company/waltham-international-college/mycompany/">
                                        <span className="u-icon u-social-icon u-social-linkedin u-icon-4">
                                            <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112">
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-6990"></use>
                                            </svg>
                                            <svg className="u-svg-content" viewBox="0 0 112 112" id="svg-6990">
                                                <circle fill="currentColor" cx="56.1" cy="56.1" r="55"></circle>
                                                <path fill="#FFFFFF" d="M41.3,83.7H27.9V43.4h13.4V83.7z M34.6,37.9c-4.6,0-7.5-3.1-7.5-7c0-4,3-7,7.6-7s7.4,3,7.5,7 C42.2,34.8,39.2,37.9,34.6,37.9z M89.6,83.7H76.2V62.2c0-5.4-1.9-9.1-6.8-9.1c-3.7,0-5.9,2.5-6.9,4.9c-0.4,0.9-0.4,2.1-0.4,3.3v22.5 H48.7c0,0,0.2-36.5,0-40.3h13.4v5.7c1.8-2.7,5-6.7,12.1-6.7c8.8,0,15.4,5.8,15.4,18.1V83.7z"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
