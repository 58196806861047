// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home'; 
import Contact from './pages/Contact'; 
import About from './pages/About'; 
import Policies from './pages/Policies'; 
import BusinessManagementBA from './pages/BusinessManagementBA'; 
import BusinessManagementBSC from './pages/BusinessManagementBSC'; 
import AccreditationAffililation from './pages/AccreditationAffililation'; 
import LoadScripts from './components/LoadScripts'; 
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import '@fortawesome/fontawesome-free/css/all.min.css';

const App = () => {
  return (
    <HelmetProvider>
      <div className="u-body u-xl-mode">
        <Router>
          <Header />
          
          <Routes>
            <Route 
              path='/' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Home.css', '/css/Footer.css']}
                  />
                  <Home />
                </>
              } 
            />
            
            <Route 
              path='/Contact' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Contact.css', '/css/Footer.css']}
                  />
                  <Contact />
                </>
              } 
            />
            
            <Route 
              path='/About-us' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/About.css', '/css/style.css', '/css/Footer.css']}
                  />
                  <About />
                </>
              } 
            />
            
            <Route 
              path='/Policies' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Footer.css']}
                  />
                  <Policies />
                </>
              } 
            />
            
            <Route 
              path='/Business-Managment-BA' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Footer.css', '/css/bm_ba.css']}
                  />
                  <BusinessManagementBA />
                </>
              } 
            />
            
            <Route 
              path='/Business-Managment-BSC' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Footer.css', '/css/bm_ba.css']}
                  />
                  <BusinessManagementBSC />
                </>
              } 
            />
            <Route 
              path='/accreditation-and-affiliation' 
              element={
                <>
                  <LoadScripts 
                    scriptsToLoad={['/js/jquery.js', '/js/nicepage.js', '/js/utils.js']}
                    cssToLoad={['/css/nicepage.css', '/css/Footer.css', '/css/accreditation-affilation.css']}
                  />
                  <AccreditationAffililation />
                </>
              } 
            />
          </Routes>
          
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
