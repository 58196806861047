// src/pages/Home.js
import React from "react";
import { Helmet } from 'react-helmet-async';

class BusinessManagementBSC extends React.Component {
    render() {
        return (
            <>
             <Helmet>
      
      <title>Business Management BSC with Foundation Year - EDA COLLEGE</title>
      
      <meta name="description" content="Welcome to EDA College's" />
      <link rel="icon" type="image/png" href="/images/edalogo.png" sizes="16x16" />

      </Helmet>
                <section className="u-align-center u-clearfix u-section-1" id="carousel_ee80">
                    <div className="u-clearfix u-sheet u-valign-bottom-lg u-valign-bottom-md u-valign-bottom-xl u-valign-middle-xs u-sheet-1">
                        <h1 className="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xl u-text-1">
                            Business Management BSC&nbsp;<br />
                            with Foundation Year
                        </h1>
                        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout">
                                <div className="u-layout-col">
                                    <div className="u-align-center-lg u-align-center-md u-align-center-sm u-container-style u-image u-image-tiles u-layout-cell u-left-cell u-right-cell u-size-30 u-image-1">
                                        <div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-container-layout-1">
                                            <div className="u-align-center u-container-style u-group u-palette-1-base u-group-1">
                                                <div className="u-container-layout u-container-layout-2"></div>
                                            </div>
                                            <img className="u-expanded-width-xs u-image u-image-2" src="/images/AdobeStock_495216732Large.jpeg" data-image-width="4896" data-image-height="2721" />
                                        </div>
                                    </div>
                                    <div className="u-align-center u-container-style u-layout-cell u-left-cell u-right-cell u-size-30 u-layout-cell-2">
                                        <div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-container-layout-3">
                                            <p className="u-text u-text-2">
                                                This Business Management BSC with Foundation Year in Business degree is delivered in
                                                partnership with Newman University.&nbsp;<br />
                                                <br />
                                                Do you want to develop the essential management skills and experience to launch your business
                                                career?&nbsp;<br />
                                                <br />
                                                Management is at the heart of the skillset needed to succeed in modern business. On this course
                                                you’ll cover all the essential functions of management, operations, and human resources (HR),
                                                developing a wide-ranging knowledge of organisations and their business environment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="u-align-left u-clearfix u-container-align-center u-custom-color-1 u-section-3" id="carousel_d03e">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-expanded-width u-tab-links-align-justify u-tabs u-tabs-1">
                            <ul className="u-spacing-0 u-tab-list u-unstyled" role="tablist">
                                <li className="u-tab-item" role="presentation">
                                    <a className="active u-button-style u-tab-link u-text-active-white u-text-hover-grey-75 u-text-white u-tab-link-1" id="link-tab-0da5" href="#tab-0da5" role="tab" aria-controls="tab-0da5" aria-selected="true"> Entry Requirements</a>
                                </li>

                            </ul>
                            <div className="u-tab-content">
                                <div className="u-align-left u-container-style u-tab-active u-tab-pane u-white u-tab-pane-1" id="tab-0da5" role="tabpanel" aria-labelledby="link-tab-0da5">
                                    <div className="u-container-layout u-container-layout-1">
                                        {/* <h5 className="u-align-center u-custom-font u-font-montserrat u-text u-text-1"> Course overview</h5> */}
                                        <p className="u-align-left u-text u-text-2">
                                            <strong>Admission open for january subject to validation.</strong>&nbsp;<br />
                                            <br />Applicants must achieve at least 96 UCAS points including a minimum of CC at A level or equivalent (e.g.MM at BTEC Diploma; MPP at BTEC Extended Diploma) towards the total tariff.&nbsp;<br />
                                            <br />Access Students can achieve the requirements with the following combination of Distinction, Merit and/ or Pass grades at level 3 achieved from a completed Access course. 96 UCAS Points: D21-M3-P21; D18-M9-P18; D15-M15-P15; D12-M21-P12; D9-M27-P9; D6-M33-P6; D3-M39-P3; D0-M45-P0.&nbsp;<br />
                                            <br />Appropriate T Levels will be accepted as part of the usual entry requirements.&nbsp;<br />
                                            <br />For applicants who are unsure that they will achieve the above UCAS tariff, the more appropriate course for them would be our Business Management BSC with Foundation Year, which enables such applicants to access a university degree via a four year route. Upon successful completion of their foundation year, students will progress to Year 1 of a named degree. Whilst not a condition of entry onto the Foundation Year, students wishing to follow particular named routes with additional entry requirements, will need to meet these requirements before they make the transition from their foundation year to year 1.&nbsp;<br />
                                            <br /><strong>Applying Direct Option
                                            </strong>
                                            <br />Thank you for choosing EDA College for your Higher Education.&nbsp;<br />
                                            <br />Your application will be considered for the next intake.&nbsp;<br />
                                            <br />Following review of your application by EDA College, information will be shared with Newman University who will contact you with final confirmation of a decision on your application.&nbsp;<br />
                                            <br />Please contact EDA College admission team on 02039 300748 in case of enquiries or any assistance required. Please click here to <a
                                                href="https://e-dan.edacollege.co.uk/applicant-form" style={{ color: 'black', fontWeight: 'Bold' }}>Apply Online</a>.&nbsp;<br />
                                        </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-section-4" id="sec-77f0">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <h1 className="u-text u-text-default u-text-1"> What Will You Study?</h1>
                        <p className="u-text u-text-default u-text-2">
                            The module details given below are subject to change and are the latest example of the curriculum available on this course of study.&nbsp;<br />
                            <br />Students will study a variety of core modules across their four years of study.
                        </p>
                    </div>
                </section>

                <section className="u-align-center u-clearfix u-grey-5 u-section-5" id="sec-0b76">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-accordion u-spacing-10 u-accordion-1">
                            {/* Accordion Item 1 */}
                            <div className="u-accordion-item">
                                <a
                                    className="active u-accordion-link u-active-palette-1-base u-button-style u-hover-palette-1-light-1 u-palette-1-light-1 u-radius-14 u-accordion-link-1"
                                    id="link-accordion-0781"
                                    aria-controls="accordion-0781"
                                    aria-selected="true"
                                    style={{
                                        background: 'linear-gradient(rgba(34, 102, 0, 0.82), rgba(227, 198, 56, 0.75))',
                                    }}
                                >
                                    <span className="u-accordion-link-text">Year 1</span>
                                    <span className="u-accordion-link-icon u-icon u-icon-rectangle u-text-white u-icon-1">
                                        <svg
                                            className="u-svg-link"
                                            preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 42 42"
                                        />
                                        <svg
                                            className="u-svg-content"
                                            viewBox="0 0 42 42"
                                            x="0px"
                                            y="0px"
                                            id="svg-f4c1"
                                            style={{ enableBackground: 'new 0 0 42 42' }}
                                        >
                                            <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 "></polygon>
                                        </svg>
                                    </span>
                                </a>
                                <div
                                    className="u-accordion-active u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-1"
                                    id="accordion-0781"
                                    aria-labelledby="link-accordion-0781"
                                >
                                    <div className="u-container-layout u-valign-bottom u-container-layout-1">
                                        <ul className="u-custom-list u-text u-text-2">
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Academic and Employability Skills
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Global Business Environment
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Accounting Fundamentals
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Financial Management
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Business Economics

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Corporate and Business Law


                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Accordion Item 2 */}
                            <div className="u-accordion-item">
                                <a
                                    className="u-accordion-link u-active-palette-1-base u-button-style u-hover-palette-1-light-1 u-palette-1-light-1 u-radius-14 u-accordion-link-3"
                                    id="link-accordion-a910"
                                    aria-controls="accordion-a910"
                                    aria-selected="false" style={{
                                        background: 'linear-gradient(rgba(34, 102, 0, 0.82), rgba(227, 198, 56, 0.75))',
                                    }}
                                >
                                    <span className="u-accordion-link-text">Year 2</span>
                                    <span className="u-accordion-link-icon u-icon u-icon-rectangle u-text-white u-icon-3">
                                        <svg
                                            className="u-svg-link"
                                            preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 42 42"
                                        >
                                            <use xlinkHref="#svg-9669"></use>
                                        </svg>
                                        <svg
                                            className="u-svg-content"
                                            viewBox="0 0 42 42"
                                            x="0px"
                                            y="0px"
                                            id="svg-9669"
                                            style={{ enableBackground: 'new 0 0 42 42' }}
                                        >
                                            <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 "></polygon>
                                        </svg>
                                    </span>
                                </a>
                                <div
                                    className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-3"
                                    id="accordion-a910"
                                    aria-labelledby="link-accordion-a910"
                                >
                                    <div className="u-container-layout u-container-layout-3">
                                        {/* <p className="u-text u-text-5">Core modules (students are required to take):</p> */}
                                        <ul className="u-custom-list u-text u-text-6">
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                People Management

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Marketing Management

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Operations and Services Management

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Research Methods

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Innovation and Entrepreneurship


                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Work Related Learning



                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Additional Accordion Item */}
                            <div className="u-accordion-item">
                                <a
                                    className="u-accordion-link u-active-palette-1-base u-button-style u-hover-palette-1-light-1 u-palette-1-light-1 u-radius-14 u-accordion-link-3"
                                    id="link-accordion-a973"
                                    aria-controls="accordion-a973"
                                    aria-selected="false" style={{
                                        background: 'linear-gradient(rgba(34, 102, 0, 0.82), rgba(227, 198, 56, 0.75))',
                                    }}
                                >
                                    <span className="u-accordion-link-text">Year 3</span>
                                    <span className="u-accordion-link-icon u-icon u-icon-rectangle u-text-white u-icon-3">
                                        <svg
                                            className="u-svg-link"
                                            preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 42 42"
                                        >
                                            <use xlinkHref="#svg-9669"></use>
                                        </svg>
                                        <svg
                                            className="u-svg-content"
                                            viewBox="0 0 42 42"
                                            x="0px"
                                            y="0px"
                                            id="svg-9669"
                                            style={{ enableBackground: 'new 0 0 42 42' }}
                                        >
                                            <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 "></polygon>
                                        </svg>
                                    </span>
                                </a>
                                <div
                                    className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-4"
                                    id="accordion-a973"
                                    aria-labelledby="link-accordion-a973"
                                >
                                    <div className="u-container-layout u-container-layout-4">
                                        {/* <p className="u-text u-text-default u-text-7">
              Core modules (students are required to take):
            </p> */}
                                        <ul className="u-custom-list u-text u-text-8">
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                International Business

                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Leadership and Change Management
                                            </li>

                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Strategic Management
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Corporate Social Responsibility and Sustainability
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Global Economic and Financial Systems
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Global Innovation Management
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Digital Marketing
                                            </li>
                                            <li>
                                                <div className="u-list-icon">
                                                <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">–</div>
                                                </div>
                                                Strategic Marketing
                                            </li>
                                        </ul>
                                        {/* <p className="u-text u-text-11">*There are pre-requisites for this module</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="u-clearfix u-custom-color-1 u-section-6" id="sec-b015">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout">
                                <div className="u-layout-row">
                                    <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                        <div className="u-container-layout u-valign-top u-container-layout-1">
                                            <h3 className="u-text u-text-default u-text-1">Apply online</h3>
                                            <p className="u-text u-text-default u-text-2">
                                                Thank you for choosing EDA College for your Higher Education.&nbsp;<br />
                                                <br />
                                                Our intake dates are January , September. Your application will be considered for the next intake.&nbsp;<br />
                                                <br />
                                                Following review of your application by EDA College, information will be shared with Newman University, who will contact you with final confirmation of a decision on your application.&nbsp;<br />
                                                <br />
                                                Please contact EDA College admissions team on&nbsp;020 3930 0478&nbsp;if you have any enquiries or require assistance. Please click here to&nbsp;
                                            </p>
                                            <a
                                                href="https://e-dan.edacollege.co.uk/applicant-form"
                                                className="u-border-2 u-border-hover-palette-4-light-1 u-border-palette-2-light-2 u-btn u-btn-round u-button-style u-hover-palette-4-light-2 u-none u-radius u-btn-1"
                                                target="_blank" rel="noopener noreferrer"
                                            >
                                                Apply Now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                                        <div className="u-container-layout u-valign-top u-container-layout-2">
                                            <h3 className="u-text u-text-default u-text-3">Learning and Teaching Methods</h3>
                                            <p className="u-text u-text-default u-text-4">
                                                EDA seeks to provide a stimulating learning environment that enables students from diverse backgrounds and experiences to achieve their full potential. We offer a range of flexible and high-quality learning experiences alongside the necessary resources and support to enhance your academic and personal development.&nbsp;<br />
                                                <br />
                                                Students are expected to take an active role in their own learning. Staff take an inclusive approach to learning where all students, regardless of their background and level of ability, participate in the learning process. Students take responsibility for their own achievement, engaging in active discussion with peers and staff and following a practical learning approach. Staff encourage learning experiences that promote teamwork, critical analysis, reflection, and collaboration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="u-clearfix u-section-7" id="sec-28b7">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <h3 className="u-text u-text-default u-text-1">Student Finance</h3>
                        <p className="u-text u-text-default u-text-2">
                            UK &amp; EU Students (with settled status): Tuition fees are £9,250* a year for this course in 2023/2024. Depending on government policy, tuition fees may rise with inflation in future years. Tuition fees for 2024/25 entry will be set in summer 2024. Living costs, e.g., travel, and food, will also need to be taken into consideration.&nbsp;<br />
                            <br />
                            *Support may be available to help with this cost. To check if you’re eligible for student finance, find out how much you can get, how to apply, and when you start repaying, please follow the link:&nbsp;
                            <a
                                href="https://www.gov.uk/get-undergraduate-student-loan"
                                className="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.gov.uk/get-undergraduate-student-loan
                            </a>
                            &nbsp;When you apply for your student loan, you will need to supply the correct UCAS code for your course of study.&nbsp;<br />
                            <br />
                            This code will depend on your study location, course, and start date (see table below) and will be sent to you in any offer email you receive from Newman University. Please ensure that you use the correct code to avoid delays in processing your application and receiving any student loan.
                        </p>
                    </div>
                </section>

                <section className="u-align-center u-clearfix u-section-8" id="
        
        ">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-expanded-width u-table u-table-responsive u-table-1">
                            <table className="u-table-entity u-table-entity-1">
                                <colgroup>
                                    <col width="10.3%" />
                                    <col width="12.7%" />
                                    <col width="49.2%" />
                                    <col width="15.2%" />
                                    <col width="12.6%" />
                                </colgroup>
                                <thead className="u-custom-font u-font-courier-new u-palette-4-base u-table-header u-table-header-1">
                                    <tr>
                                        <th className="u-custom-color-1 u-table-cell u-table-cell-1">Partner</th>
                                        <th className="u-custom-color-1 u-table-cell u-table-cell-2">Location</th>
                                        <th className="u-custom-color-1 u-table-cell u-table-cell-3">Course</th>
                                        <th className="u-custom-color-1 u-table-cell u-table-cell-4">Start date</th>
                                        <th className="u-custom-color-1 u-table-cell u-table-cell-5">UCAS course code</th>
                                    </tr>
                                </thead>
                                <tbody className="u-table-body">
                                    <tr>
                                        <td className="u-border-1 u-border-grey-30 u-table-cell">Newman</td>
                                        <td className="u-border-1 u-border-grey-30 u-table-cell">Birmingham</td>
                                        <td className="u-border-1 u-border-grey-30 u-table-cell">Business And Management BSC with Foundation Year</td>
                                        <td className="u-border-1 u-border-grey-30 u-table-cell">September</td>
                                        <td className="u-border-1 u-border-grey-30 u-table-cell">N821</td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                        <h6 className="u-text u-text-default u-text-1">
                            For information and advice on financing your studies, please contact us on&nbsp;020 3930 0478
                        </h6>
                        <p className="u-text u-text-default u-text-2">
                            <span style={{ fontWeight: 700 }}>Additional costs</span>
                            <br />
                            Students are advised to consider the following in addition to annual tuition fees while budgeting:
                            <br />
                        </p>
                        <ul className="u-align-left u-text u-text-default u-text-3">
                            <li>
                                College will provide the list of books required and recommended at the beginning of the course. Students can access e-books and other resources from our online library. Some books are available from the College Library to borrow, but you may choose to purchase your own.
                            </li>
                            <li>You will need to include placement/s travel and associated costs too.</li>
                            <li>The College provides students with a printing facility free of charge.</li>
                        </ul>
                    </div>
                </section>

                <section className="u-clearfix u-grey-10 u-section-9" id="sec-880e">
                    <div className="u-clearfix u-sheet u-sheet-1"></div>
                </section>
            </>
        );
    }
}

export default BusinessManagementBSC;