// src/pages/Home.js
import React from "react";
import { Helmet } from 'react-helmet-async';

class Policies extends React.Component {

    render() {
        return (
            <>
            <Helmet>
      
            <title>Policies - EDA COLLEGE</title>
            
            <meta name="description" content="Welcome to EDA College's homepage, where you can explore our values, principles, and various courses like Business Management BA and BSc." />
            <link rel="icon" type="image/png" href="/images/edalogo.png" sizes="16x16" />

            </Helmet>
                <section className="u-clearfix u-container-align-center u-section-1" id="carousel_328e">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-gutter-20 u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout main-heading">
                                <div className="u-layout-row">
                                    <p style={{ color: 'red', fontSize: '36px' }}>
                                        Policies will update soon!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Policies;
