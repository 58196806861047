// src/pages/Home.js
import React from "react";
import { Helmet } from 'react-helmet-async';

class AccreditationAffililation extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Accreditation And Affililation - EDA COLLEGE</title>
          <meta
            name="description"
            content="Welcome to EDA College's accreditation and affiliation page."
          />
          <link
            rel="icon"
            type="image/png"
            href="/images/edalogo.png"
            sizes="16x16"
          />
        </Helmet>
        <section>
          <div className="container" style={{
              display: "flex", // Use flexbox to center horizontally
              flexDirection: "column", // Stack elements vertically
              alignItems: "center", // Center cards horizontally
              justifyContent: "center", // Center cards vertically if height expands
             
            }}>
            <div className="row accredation-card" style={{ maxWidth: "960px", width: "100%", textAlign: "center" }}>
              {/* Mayor of London Card */}
              <div className="card">
                <a
                  href="https://www.london.gov.uk/who-we-are/what-mayor-does/role-mayor-london"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/Mayor_of_London_GREY_RGB.png"
                    alt="Mayor of London Logo"
                    style={{ width: "50%" }}
                  />
                </a>
                <div>
                  <p style={{ color: "black", fontWeight: "bold" }}>
                    The Mayor of London sets the budget and is responsible for
                    making London a better place for everyone who visits, lives
                    or works in the city. The Mayor is elected every four years.
                  </p>
                </div>
              </div>

              {/* Cyber Essentials Card */}
              <div className="image-left card">
                <a
                  href="https://registry.blockmarktech.com/certificates/984dc21e-bf49-4ad7-ab0a-a770c4cdb842/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="images/download.png" alt="Cyber Essentials Logo" />
                </a>
                <div>
                  <h1 className="title">
                    <a
                      href="https://registry.blockmarktech.com/certificates/984dc21e-bf49-4ad7-ab0a-a770c4cdb842/"
                      className="title"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cyber Essentials
                    </a>
                    <div className="b-tooltip is-info is-top is-small is-multiline">
                      <div className="tooltip-content" style={{ display: "none" }}>
                        We have reviewed this certification scheme.
                      </div>
                      <div className="tooltip-trigger">
                        <span className="icon">
                          <i
                            className="material-icons has-text-info"
                            style={{ color: "#3e8ed0" }}
                          >
                            verified
                          </i>
                        </span>
                      </div>
                    </div>
                  </h1>
                  <p style={{ color: "black", fontWeight: "bold" }}>
                    Effective, Government backed minimum standard scheme that
                    protects against the most common cyber attacks - self
                    assessed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AccreditationAffililation;
