import React from "react";
import { Helmet } from 'react-helmet-async';

class Home extends React.Component {
  render() {
    return (
      <>
      <Helmet>
      
          <title>Home - EDA COLLEGE</title>
        
          <meta name="description" content="Welcome to EDA College's homepage, where you can explore our values, principles, and various courses like Business Management BA and BSc." />
          <link rel="icon" type="image/png" href="/images/edalogo.png" sizes="16x16" />

        </Helmet>
        <section
          className="u-align-center-sm u-align-center-xs u-align-right-lg u-align-right-md u-align-right-xl u-clearfix u-image u-image-contain u-section-1"
          id="sec-d422"
          data-image-width="1280"
          data-image-height="854"
        >
          <div className="u-clearfix u-sheet u-valign-middle-sm u-valign-middle-xs u-sheet-1">
            <div
              className="u-align-center u-container-style  u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-shape-rectangle u-group-1"
              data-animation-name="customAnimationIn"
              data-animation-duration="1500"
              data-animation-delay="500" style={{
                background: 'linear-gradient(rgba(34, 102, 0, 0.82), rgba(227, 198, 56, 0.75))'}}
            >
              <div className="u-container-layout u-valign-middle-xl u-container-layout-1">
                <h2 className="u-text u-text-1" style={{color:'white'}}> EDA COLLEGE</h2>
                <p className="u-text u-text-2" style={{color:'white'}}>“Streamline your career with EDA”</p>

              </div>
            </div>
            <div className="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div
                  className="u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-1"
                  data-animation-name="customAnimationIn"
                  data-animation-duration="1500"
                  data-animation-delay="750"
                >
                  <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                    <span
                      className="u-icon u-icon-circle u-palette-2-base u-text-palette-1-base u-icon-1"
                      data-animation-name="customAnimationIn"
                      data-animation-duration="1750"
                      data-animation-delay="500"
                    >
                       <img src="/images/honesty.png" alt="Icon" style={{width:'50px'}}/>
                    </span>
                    <h4 className="u-align-center u-text u-text-custom-color-1 u-text-3">
                      Respect
                    </h4>
                  </div>
                </div>
                <div
                  className="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-2"
                  data-animation-name="customAnimationIn"
                  data-animation-duration="1500"
                  data-animation-delay="750"
                >
                  <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
                    <span
                      className="u-icon u-icon-circle u-palette-2-base u-text-palette-1-base u-icon-1"
                      data-animation-name="customAnimationIn"
                      data-animation-duration="1750"
                      data-animation-delay="500"
                    >
                      <img src="/images/hand.png" alt="Icon" style={{width:'50px'}}/>
                    </span>
                    <h4 className="u-text u-text-custom-color-1 u-text-4">
                      Affordability
                    </h4>
                  </div>
                </div>
                <div
                  className="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-3"
                  data-animation-name="customAnimationIn"
                  data-animation-duration="1500"
                  data-animation-delay="750"
                >
                  <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4">
                    <span
                      className="u-file-icon u-icon u-icon-circle u-palette-2-base u-text-palette-1-base u-icon-3"
                      data-animation-name="customAnimationIn"
                      data-animation-duration="1750"
                      data-animation-delay="500"
                    >
                      <img src="/images/benefits.png" alt="Icon" style={{width:'50px'}}/>
                    </span>
                    <h4 className="u-text u-text-custom-color-1 u-text-5">
                      Commitment and Excellence
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="u-align-center u-clearfix u-container-align-center u-section-2" id="carousel_a589">
          <div className="u-clearfix u-sheet u-sheet-1">
            <h2
              className="u-align-center u-text u-text-custom-color-1 u-text-1"
             
            >
              Our Courses
            </h2>
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div
                  className="u-align-center u-border-2 u-border-grey-10 u-container-style u-list-item u-radius u-repeater-item u-shape-round u-video-cover u-white u-list-item-1"
                  data-animation-name="customAnimationIn"
                  
                  data-href="/Business-Managment-BA"
                >
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <img
                      className="u-expanded-width u-image u-image-round u-radius-15 u-image-1"
                      src="/images/AdobeStock_127674449Large.jpeg"
                      alt="HSC Image"
                      data-image-width="4896"
                      data-image-height="2721"
                    />
                    <h5 className="u-text u-text-custom-color-1 u-text-2">
                      Business Management BA (Hons) with Foundation Year
                    </h5>
                    <p className="u-text u-text-3">
                      This Business Management BA (Hons) with Foundation Year in Business degree is delivered in partnership with Newman University.
                    </p>
                  </div>
                </div>

                <div
                  className="u-align-center u-border-2 u-border-grey-10 u-container-style u-list-item u-radius u-repeater-item u-shape-round u-white u-list-item-2"
                  data-animation-name="customAnimationIn"
                  data-animation-duration="1500"
                  data-animation-delay="750"
                  data-href="/Business-Managment-BSC"
                >
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <img
                      className="u-expanded-width u-image u-image-round u-radius-15 u-image-2"
                      src="/images/AdobeStock_495216732Large.jpeg"
                      alt="DSCF Image"
                      data-image-width="4896"
                      data-image-height="3264"
                    />
                    <h5 className="u-text u-text-custom-color-1 u-text-4">
                    Business Management BSC with Foundation Year
                    </h5>
                    <p className="u-text u-text-5">
                    The Business Management BSc with Foundation Year in Business degree is delivered at the EDA College campus in Birmingham, in partnership with Newman University.
                    </p>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Home;
