import React from "react";


class Header extends React.Component {
    render() {
        return (
            <header>
                <div className="u-clearfix u-header u-header" id="sec-fe69">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <a href="/" className="u-image u-logo u-image-1" data-image-width="1890" data-image-height="1417" title="Home">
                            <img src="/images/edalogo.png" className="u-logo-image u-logo-image-1" alt="Eda Logo" />
                        </a>
                        <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1" data-responsive-from="MD">
                            <div className="menu-collapse" style={{ fontSize: "1rem", letterSpacing: "0px", fontWeight: 700 }}>
                                <a className="u-button-style u-custom-border u-custom-border-color u-custom-borders u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-color u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="#">
                                    <svg className="u-svg-link" viewBox="0 0 24 24">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu-hamburger"></use>
                                    </svg>
                                    <svg className="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <rect y="1" width="16" height="2"></rect>
                                            <rect y="7" width="16" height="2"></rect>
                                            <rect y="13" width="16" height="2"></rect>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                            <div className="u-custom-menu u-nav-container">
                                <ul className="u-nav u-spacing-20 u-unstyled u-nav-1">
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" href="/" style={{ padding: "10px" }}>Home</a>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" href="/Contact" style={{ padding: "10px" }}>Contact</a>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" href="/About-us" style={{ padding: "10px" }}>About</a>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" href="/Policies" style={{ padding: "10px" }}>Policies</a>
                                    </li>

                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" style={{ padding: "10px" }}>Student Support</a>
                                        <div className="u-nav-popup">
                                            <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                                <li className="u-nav-item">
                                                    <a className="u-button-style u-nav-link u-white" href="https://vle.edacollege.co.uk" target="_blank" rel="noopener noreferrer">VLE</a>
                                                </li>
                                                <li className="u-nav-item">
                                                    <a className="u-button-style u-nav-link u-white" href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4765445b-32c6-49b0-83e6-1d93765276ca&redirect_uri=https%3A%2F%2Fwww.office.com%2Flandingv2&response_type=code%20id_token&scope=openid%20profile%20https%3A%2F%2Fwww.office.com%2Fv2%2FOfficeHome.All&response_mode=form_post&nonce=638421345785639063.MTRiN2RjNTktNTk0ZC00ZjcxLWFiNmYtOTFiZDczMjA2NzdjY2Y3MzI2ZWItMGYwYi00MDdhLWIwY2QtODhkMDlkZjNmNzY0&ui_locales=en-GB&mkt=en-GB&client-request-id=3618a193-1fe4-4e48-921d-53aec0dbbf47&state=c6ljqd3Y00SxX7dZGRNnng9-jHcZgcnyYh65UAcrCza7y_EVoKP5GmeWhc7eSepCpQe5HpzB1xJn1VopL3UfXNkcBm_JS1b2ePlYx7h9_bK1ebTtPegOFt_L9OU025YSGij1iL3wnCcRmiNvyji9DVAdXHBuEBVH_NdpLtd57MCfpUR2UYgFH0kBDeCtwBvJSr0gYu8E1gxapsWc8Rj7kD2LmsUSOb5yOvalRvv-hYL_MH9lGWIJuCiSXSIMd0CydGJzp3jRDn2Cvv2BVH12Yv6rp9LC9TY193pgNweeuSc&x-client-SKU=ID_NET6_0&x-client-ver=6.34.0.0&sso_reload=true" target="_blank" rel="noopener noreferrer">Email</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" rel="nofollow" style={{ padding: "10px" }}>Staff Area</a>
                                        <div className="u-nav-popup">
                                            <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                                <li className="u-nav-item">
                                                    <a className="u-button-style u-nav-link u-white" href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4765445b-32c6-49b0-83e6-1d93765276ca&redirect_uri=https%3A%2F%2Fwww.office.com%2Flandingv2&response_type=code%20id_token&scope=openid%20profile%20https%3A%2F%2Fwww.office.com%2Fv2%2FOfficeHome.All&response_mode=form_post&nonce=638421345785639063.MTRiN2RjNTktNTk0ZC00ZjcxLWFiNmYtOTFiZDczMjA2NzdjY2Y3MzI2ZWItMGYwYi00MDdhLWIwY2QtODhkMDlkZjNmNzY0&ui_locales=en-GB&mkt=en-GB&client-request-id=3618a193-1fe4-4e48-921d-53aec0dbbf47&state=c6ljqd3Y00SxX7dZGRNnng9-jHcZgcnyYh65UAcrCza7y_EVoKP5GmeWhc7eSepCpQe5HpzB1xJn1VopL3UfXNkcBm_JS1b2ePlYx7h9_bK1ebTtPegOFt_L9OU025YSGij1iL3wnCcRmiNvyji9DVAdXHBuEBVH_NdpLtd57MCfpUR2UYgFH0kBDeCtwBvJSr0gYu8E1gxapsWc8Rj7kD2LmsUSOb5yOvalRvv-hYL_MH9lGWIJuCiSXSIMd0CydGJzp3jRDn2Cvv2BVH12Yv6rp9LC9TY193pgNweeuSc&x-client-SKU=ID_NET6_0&x-client-ver=6.34.0.0&sso_reload=true" target="_blank" rel="noopener noreferrer">Staff Email</a>
                                                </li>
                                                <li className="u-nav-item">
                                                    <a className="u-button-style u-nav-link u-white" href="https://vle.edacollege.co.uk" target="_blank" rel="noopener noreferrer">VLE</a>
                                                </li>
                                                <li className="u-nav-item">
                                                    <a className="u-button-style u-nav-link u-white" href="https://e-dan.edacollege.co.uk/login" target="_blank" rel="noopener noreferrer">E-dan</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-grey-90 u-text-hover-palette-2-base" href="https://e-dan.edacollege.co.uk/applicant-form" rel="noopener noreferrer" target="_blank" style={{ padding: "10px" }}>Application Form</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="u-custom-menu u-nav-container-collapse">
                                <div className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                                    <div className="u-inner-container-layout u-sidenav-overflow">
                                        <div className="u-menu-close"></div>
                                        <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-4">
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/">Home</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/Contact">Contact</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/About-us">About</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/Policies">Policies</a>
                                            </li>

                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" >Student Support</a>
                                                <div className="u-nav-popup">
                                                    <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                                        <li className="u-nav-item">
                                                            <a className="u-button-style u-nav-link u-white" href="https://vle.edacollege.co.uk" target="_blank" rel="noopener noreferrer">VLE</a>
                                                        </li>
                                                        <li className="u-nav-item">
                                                            <a className="u-button-style u-nav-link u-white" href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4765445b-32c6-49b0-83e6-1d93765276ca&redirect_uri=https%3A%2F%2Fwww.office.com%2Flandingv2&response_type=code%20id_token&scope=openid%20profile%20https%3A%2F%2Fwww.office.com%2Fv2%2FOfficeHome.All&response_mode=form_post&nonce=638421345785639063.MTRiN2RjNTktNTk0ZC00ZjcxLWFiNmYtOTFiZDczMjA2NzdjY2Y3MzI2ZWItMGYwYi00MDdhLWIwY2QtODhkMDlkZjNmNzY0&ui_locales=en-GB&mkt=en-GB&client-request-id=3618a193-1fe4-4e48-921d-53aec0dbbf47&state=c6ljqd3Y00SxX7dZGRNnng9-jHcZgcnyYh65UAcrCza7y_EVoKP5GmeWhc7eSepCpQe5HpzB1xJn1VopL3UfXNkcBm_JS1b2ePlYx7h9_bK1ebTtPegOFt_L9OU025YSGij1iL3wnCcRmiNvyji9DVAdXHBuEBVH_NdpLtd57MCfpUR2UYgFH0kBDeCtwBvJSr0gYu8E1gxapsWc8Rj7kD2LmsUSOb5yOvalRvv-hYL_MH9lGWIJuCiSXSIMd0CydGJzp3jRDn2Cvv2BVH12Yv6rp9LC9TY193pgNweeuSc&x-client-SKU=ID_NET6_0&x-client-ver=6.34.0.0&sso_reload=true" target="_blank" rel="noopener noreferrer">Email</a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" rel="nofollow">Staff Area</a>
                                                <div className="u-nav-popup">
                                                    <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                                        <li className="u-nav-item">
                                                            <a className="u-button-style u-nav-link u-white" href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4765445b-32c6-49b0-83e6-1d93765276ca&redirect_uri=https%3A%2F%2Fwww.office.com%2Flandingv2&response_type=code%20id_token&scope=openid%20profile%20https%3A%2F%2Fwww.office.com%2Fv2%2FOfficeHome.All&response_mode=form_post&nonce=638421345785639063.MTRiN2RjNTktNTk0ZC00ZjcxLWFiNmYtOTFiZDczMjA2NzdjY2Y3MzI2ZWItMGYwYi00MDdhLWIwY2QtODhkMDlkZjNmNzY0&ui_locales=en-GB&mkt=en-GB&client-request-id=3618a193-1fe4-4e48-921d-53aec0dbbf47&state=c6ljqd3Y00SxX7dZGRNnng9-jHcZgcnyYh65UAcrCza7y_EVoKP5GmeWhc7eSepCpQe5HpzB1xJn1VopL3UfXNkcBm_JS1b2ePlYx7h9_bK1ebTtPegOFt_L9OU025YSGij1iL3wnCcRmiNvyji9DVAdXHBuEBVH_NdpLtd57MCfpUR2UYgFH0kBDeCtwBvJSr0gYu8E1gxapsWc8Rj7kD2LmsUSOb5yOvalRvv-hYL_MH9lGWIJuCiSXSIMd0CydGJzp3jRDn2Cvv2BVH12Yv6rp9LC9TY193pgNweeuSc&x-client-SKU=ID_NET6_0&x-client-ver=6.34.0.0&sso_reload=true" target="_blank" rel="noopener noreferrer">Staff Email</a>
                                                        </li>
                                                        <li className="u-nav-item">
                                                            <a className="u-button-style u-nav-link u-white" href="https://vle.edacollege.co.uk" target="_blank" rel="noopener noreferrer">VLE</a>
                                                        </li>
                                                        <li className="u-nav-item">
                                                            <a className="u-button-style u-nav-link u-white" href="https://e-dan.edacollege.co.uk/login" target="_blank" rel="noopener noreferrer">E-dan</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="https://e-dan.edacollege.co.uk/applicant-form" style={{ padding: "10px" }}>Application Form</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                            </div>
                        </nav>
                    </div>
                    <style className="u-sticky-style" data-style-id="88a4">{`
                        .u-sticky-fixed.u-sticky-88a4:before,
                        .u-body.u-sticky-fixed .u-sticky-88a4:before {
                            border: top right bottom left !important; 
                            border-style: solid !important;
                        }
                    `}</style>
                </div>
            </header>
        )
    }
}

export default Header;
